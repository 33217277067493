@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Great Vibes";
  src: url("./fonts/GreatVibes-Regular.ttf") format("truetype");
}

h1,
.h1 {
  @apply font-semibold text-2xl md:text-4xl;
}

h2,
.h2 {
  @apply font-semibold text-xl md:text-3xl;
}

h3,
.h3 {
  @apply font-semibold text-lg md:text-2xl;
}

h4,
.h4 {
  @apply font-normal text-lg md:text-xl;
}

body,
.p {
  @apply font-light text-base md:text-lg;
}

#navbar a {
  @apply font-medium text-lg md:text-base;
}

#contactForm {
  @apply text-base md:text-lg lg:text-base xl:text-lg 
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid #253341;
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0px 1000px #253341 inset;
  box-shadow: 0 0 0px 1000px #253341 inset;
  transition: background-color 5000s ease-in-out 0s;
  color: white;
}